import React, { useEffect } from "react"
import { graphql } from "gatsby"
import * as styles from "./__styles__/homepage.module.scss"
import { connect } from "react-redux"
import { updatePageURI } from "./../../redux/actions"

import Layout from "../layouts/base"
import SEO from "../atoms/seo"
import Hero from "../molecules/hero"
import Intro from "../molecules/intro"
import Cards from "../organisms/cards-centred"
import CTALarge from "../molecules/cta-large"
import Button from "../atoms/button"

// Redux
const mapDispatchToProps = {
  updatePageURIAction: updatePageURI,
}

function Page({ updatePageURIAction, data }) {
  const page = data.wpPage
  const intro = page.intro.copy
  const cards = page.cardsCentred.cardsItems
  const ctaLarge = page.ctaLarge.ctaLargeGroup
  const buttons = page.buttons.buttonsItems

  useEffect(() => {
    updatePageURIAction(page.uri)
  })

  return (
    <Layout>
      <SEO
        description={page.seo.metaDesc}
        robots={page.seo.metaRobotsNoindex}
        slug={page.uri}
        title={page.seo.title}
      />
      <Hero
        title={page.hero.title || page.title}
        lead={page.hero.lead}
        pageId={page.id}
      />
      <div className={styles.container}>
        {intro && (
          <div className={styles.intro}>
            <Intro copy={intro} />
          </div>
        )}
        {cards && (
          <div className={styles.cards}>
            <Cards cards={cards} columns={3} />
          </div>
        )}
        {ctaLarge && (
          <div className={styles.ctaLarge}>
            <CTALarge
              title={ctaLarge.title}
              copy={ctaLarge.copy}
              link={ctaLarge.link}
            />
          </div>
        )}
        <div className={styles.buttons}>
          {buttons.map((button, index) => (
            <Button
              link={button.link}
              type={"large"}
              colour={index === 0 ? "#330072" : "#005EB8"}
              key={index}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default connect(null, mapDispatchToProps)(Page)

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      seo {
        title
        metaDesc
        metaRobotsNoindex
      }
      hero {
        title
        lead
      }
      intro {
        copy
      }
      cardsCentred {
        cardsItems {
          title
          copy
          link {
            url
            title
            target
          }
        }
      }
      ctaLarge {
        ctaLargeGroup {
          title
          copy
          link {
            title
            url
            target
          }
        }
      }
      buttons {
        buttonsItems {
          link {
            url
            title
            target
          }
        }
      }
    }
  }
`
